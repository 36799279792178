import React from "react";
// Customizable Area Start
import { Box, Dialog, Grid, IconButton, LinearProgress, styled } from "@material-ui/core";
import Footer from "../../../components/src/footer.web";
import Header from "../../../components/src/Header.web";
import { closeIcon, keyExpend, document, overlapping, whiteCalender, download, leftArrow, listView, genaretingDownload } from "./assets";
import Chart from 'react-apexcharts';
import PublicationLandingPage from "./PublicationLandingPage.web";
import ProductLandingPage from "./ProductLandingPage.web";
import Loader from "../../../components/src/Loader.web";
import {Link} from "react-router-dom"
import ProvisionalSpecification from './ProvisionalSpecification.web';
// Customizable Area End

import KeyFeatureLandingPageController, {
  Props,
} from "./KeyFeatureLandingPageController";

export default class KeyFeatureLandingPage extends KeyFeatureLandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  gridViewFunction = () => {
    return (
      <Box className="keyFeature-gridView">
        <Grid container spacing={3}>
          {this.state.patentSerpData?.map((item, index) => (
            <Grid item xs={this.state.girdView ? 6 : 12} key={index}>
              <Box className={this.state.girdView ? "keyFeature-white-container-gridView" : "keyFeature-white-container"}>
                {!this.state.girdView &&
                  <Box className="keyFeature-main-text">
                    {item?.title}
                  </Box>
                }
                {item?.images &&
                  <Box className={this.state.girdView ? "keyFeature-img-main-gridView" : "keyFeature-img-main"}>
                    {item?.images?.map((images, inde) => (
                      <Box className="img-loop">
                        <img className="img-class" src={images} alt={`figures-${inde}`} />
                      </Box>
                    ))}
                  </Box>
                }
                {this.state.girdView && <Box className="keyFeature-main-text-gridView">
                  {item?.title}
                </Box>}
                <Grid item xs={12}>
                  <Box className={this.state.girdView ? "keyFeature-Id-btn-gridView" : "keyFeature-Id-btn"} id="list-grid">
                    {item?.publication_number}
                  </Box>
                </Grid>
                <Box className="keyFeature-p-text">{item?.abstract}</Box>
                <Box className={this.state.girdView ? "keyFeature-chart-box-gridView" : "keyFeature-chart-box"}>
                  <Box className={this.state.girdView ? "keyFeature-chart-main-box-gridView" : "keyFeature-chart-main-box"}>
                    <Chart
                      options={{
                        chart: {
                          height: 274,
                          type: 'line',
                          zoom: {
                            enabled: false
                          },
                          toolbar: {
                            show: false
                          }
                        },
                        xaxis: {
                          categories: [2005, 2006, 2007, 2008],
                          labels: {style: charts.xaxisChart},
                          title: { text: 'Years', style: charts.titleBody},
                        },
                        yaxis: {
                          title: {text: 'Applications', style: charts.yaxisChart},
                          labels: { formatter: (value: { toLocaleString: () => string; }) => value.toLocaleString(),
                            style: charts.labelsCss,
                          },
                        },
                        markers: { size: 5,colors: ['#DC2626']},
                        stroke: {curve: 'straight'},
                      }}
                      series={[
                        {
                          name: 'Applications',
                          color: '#DC2626',
                          data: [250000, 350000, 450000, 550000],
                        },
                      ]}
                      type="line"
                      height={274}
                      width="100%"
                    />
                  </Box>
                  <Box className={this.state.girdView ? "keyFeature-Overlapping-main-box-gridView" : "keyFeature-Overlapping-main-box"}>
                    <Box 
                    className="overlapping-head-Box"
                    >
                      <Box className="overlapping-text">
                        Overlapping
                        </Box>
                      <Box className="percent-text">
                        Percent
                        </Box>
                    </Box>
                    <Box className="overlapping-border"></Box>
                    {this.state.overlappingData.map((item, index) => (
                      <Box 
                      key={index} 
                      className="overlapping-box-container">
                        <Box 
                        className="overlapping-Label-percentage">
                          <Box 
                          className="overlapping-Label"> {item.label}</Box>
                          <Box className="overlapping-percentage"> {item.value}%</Box>
                        </Box>
                        <LinearProgress variant="determinate" value={item.value} className="KeyFeature-progressStyle"/>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box className="buttons-main-box">
                  <Box className="buttons-box">
                    <img 
                    src={overlapping} 
                    alt="overlapping" />
                    <Box className="button-text">20% Overlapping</Box>
                  </Box>
                  {this.state.girdView &&
                    <Link className="link-text" 
                    to={`/LandingPageViewReportDescriptionWeb/${item.publication_number}`}>
                      <Box id="descriptionPageGrid" className="buttons-box-red">
                        <img 
                        src={whiteCalender} 
                        alt="whiteCalender" />
                        <Box className="button-text-white"> View More </Box>
                      </Box>
                    </Link>
                  }
                  <Link className="link-text" to={`/SimilarDocuments/${item.publication_number}`}>
                  <Box className="buttons-box">
                    <img src={document} alt="document" />
                    <Box className="button-text">
                      Similar Document
                    </Box>
                  </Box>
                  </Link>
                  {!this.state.girdView &&
                    <Link 
                    className="link-text" 
                    to={`/LandingPageViewReportDescriptionWeb/${item.publication_number}`}
                    >
                      <Box 
                      className="buttons-box-red"
                      id="descriptionPage" 
                      >
                        <img 
                        src={whiteCalender} 
                        alt="whiteCalender" 
                        />
                        <Box className="button-text-white">View More</Box>
                      </Box>
                    </Link>
                  }
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    )
  };
  handleNavigation = () => {
    const { selectResult, loading, patentSerpData, publicationSerpData, productDetails, girdView } = this.state;
    const { navigation } = this.props;
    const hasPatentResults = patentSerpData?.length > 0 && this.state.patenterrors;
    const hasPublicationResults = publicationSerpData?.length > 0 && this.state.publicationerror;
    const hasProductResults = productDetails?.length > 0;
    return (
      <Box>
        {selectResult === 'Patent' && !loading && (
          hasPatentResults ? (
            this.gridViewFunction()
          ) : (
            <Box component="div" className="no-result-text">No Results Found</Box>
          )
        )}
        {selectResult === 'Publication' && !loading && (
          hasPublicationResults ? (
            <PublicationLandingPage
              publicationSerpData={publicationSerpData}
              gridView={girdView}
              navigation={navigation}
              id="" 
            />
          ) : (
            <Box component="div" className="no-result-text">No Results Found</Box>
          )
        )}
        {selectResult === 'Products' && !loading && (
          hasProductResults ? (
            <ProductLandingPage
              navigation={navigation}
              id=""
              gridView={girdView}
              productDetails={productDetails}
            />
          ) : (
            <Box component="div" className="no-result-text">No Results Found</Box>
          )
        )}
        {
          selectResult === 'Provisional Specification' && !loading && (
          <ProvisionalSpecification />
        )}
        {
          selectResult === 'Non-Provisional Specification' && !loading && (
          <ProvisionalSpecification />
        )}
      </Box>
    );
  }  
 
  // Customizable Area End

  render() { 
    return (
      <>
        <Loader 
        loading={this.state.loading} 
        />
        <Header title={""} />
        <KeyFeatureLandingPageContainerCss>
          <Grid container>
            <Box 
            className="back-arrowimain-box">
              <Box 
              className="back-arrow-box">
                <img 
                src={leftArrow} 
                alt="leftArrow" 
                id="navigate-landingPage"
                 className="back-arrow-img"
                  onClick={() => this.goBackToLandingPage()} 
                  />
                <Box 
                className="back-arrow-text" 
                id="heading">View Key Features</Box>
              </Box>
            </Box>
          </Grid>
          <Box className="result-Main">
            <Box>
              <Box className="caseId-main"> Case ID : <Box component="span" className="box-span">{this.state.caseid}</Box>
              </Box>
              <Box className="top-ten-result">Showing top 10 results</Box>
              <Box className="keyfeature-box">
                <Grid item xs={12} 
                className="keyFeature-search-grid"
                >
                  <Box 
                  className="keyFeature-search-box-looking">
                    {this.state.selectedTabs.map((item: string, index: number) => (
                      <Box 
                      className={item !== this.state.selectResult ? "keyFeature-box-search-loop" : "keyFeature-box-search-loop-select"}
                        id={`statusChange${index}`} 
                        onClick={() => this.onClickSetStutus(item)}
                        >
                        <Box 
                        key={index} 
                        className="keyFeature-box-name">
                          {item}
                          </Box>
                      </Box>
                    ))}
                  </Box>
                </Grid>
                <Box className="download-Report-main">
                  <Box 
                  className="report-buttons-box" 
                  id="downloadOpen" 
                  onClick={() => this.downloadModelOpen()}
                  >
                    <img 
                    src={download} 
                    alt="download" />
                    <Box className="report-button-text">Download Report</Box>
                  </Box>
                  <Box className="keyFeature-img-box">
                    <img className="listView-style" 
                    src={this.state.girdView ? listView : keyExpend} 
                    id="listView" 
                    alt="keyExpend"
                     onClick={() => this.handleClickGridView()} />
                  </Box>
                </Box>
                <ReportDialog
                  open={this.state.downReportModel}
                  fullWidth
                  aria-labelledby="customized-dialog-title"
                  id="downloadClose"
                  PaperProps={{
                    elevation: 0,
                    className: "download-modal",
                  }}
                  BackdropProps={{
                    className: "backdrop-modal",
                  }}
                >
                  <Box className="download-closeIconStyle">
                    <IconButton aria-label="close">
                      <img
                        src={closeIcon}
                        width={24}
                        alt="closeIcon"
                        id="download-Click-Close"
                        height={24}
                        onClick={() => this.downloadModelClose()}
                      />
                    </IconButton>
                  </Box>
                  <Box className="down-box">
                    <Box className="download-main-box">
                      <img
                        className="download-Icon-style"
                        src={genaretingDownload}
                        alt="genaretingDownload"
                      />
                      <Box>
                        <Box className="download-title-model"> We are generating your report,</Box>
                        <Box className="download-model-Text">Once completed you can Download it from your Profile Settings</Box>
                      </Box>
                    </Box>
                  </Box>
                </ReportDialog>
              </Box>
            </Box>
           {this.handleNavigation()}
          </Box>
        </KeyFeatureLandingPageContainerCss>
        <Footer />
      </>
    );
  }
}

// Customizable Area Start
const ReportDialog = styled(Dialog)({
  "& .download-modal": {
    borderRadius: 0,
    background: '#F5F5F4',
    maxWidth: 738,
    height: 298,
  },
  "& .backdrop-modal": {
    backgroundColor: 'rgba(15, 23, 42, 0.4)'
  },
  "& .download-main-box": {
    alignItems: "center",
    gap: 30,
    flexDirection: "column",
    display: "flex",
    textAlign: "center",
  },
  "& .download-Icon-style": {
    cursor: 'pointer',
  },
  "& .download-title-model": {
    fontFamily: 'Inter',
    fontWeight: 700,
    color: '#4F5356',
    marginBottom: "15px",
    fontSize: 20,
  },
  "& .download-model-Text": {
    fontWeight: 700,
    fontFamily: 'Inter',
    color: '#4F5356',
    fontSize: 20,
  },
  "& .down-box": {
    padding: 60,
    height: "100%",
  },
  "& .download-closeIconStyle": {
    position: 'absolute',
    right: 10,
    top: 8,
  },
});

const charts = {
  xaxisChart: {
    colors: '#DF4833',
    fontSize: '12px',
  },
  titleBody: {
    fontSize: '12px',
    fontWeight: 'bold',
    color: '#F0ABA1',
  },
  yaxisChart: {
    fontWeight: 'bold',
    color: '#F0ABA1',
    fontSize: '12px',
  },
  labelsCss: {
    colors: '#DF4833',
    fontSize: '12px',
  }
};

const KeyFeatureLandingPageContainerCss = styled(Box)({
  "& .back-arrowimain-box": {
    padding: "30px 0px 20px 108px",
    background: "#E8E7E7",
    "@media(max-width:600px)": {
      padding: "40px 20px",
    },
    "@media(max-width:1280px)": {
      padding: "40px 40px"
    },
    "@media(max-width:960px)": {
      padding: "40px 20px"
    },
  },
  "& .back-arrow-box": {
    display: "flex",
    gap: 16,
    alignItems: "center",
  },
  "& .back-arrow-img": {
    cursor: "pointer"
  },
  "& .back-arrow-text": {
    fontSize: "18px",
    fontFamily: "Inter",
    color: "#212324",
    fontWeight: 400,
  },
  "& .result-Main": {
    padding: "30px 108px 20px",
    background: "#E8E7E7",
    "@media(max-width:600px)": {
      padding: "40px 20px",
    },
    "@media(max-width:1280px)": {
      padding: "40px 40px"
    },
    "@media(max-width:960px)": {
      padding: "40px 20px"
    },
  },
  "& .keyFeature-white-container": {
    borderRadius: 8,
    padding: "32px 24px",
    background: "#F5F5F4",
  },
  "& .no-result-text": {
    fontWeight: 700,
    fontSize: 24,
    fontFamily: "Inter",
    justifyContent: "center",
    height: "200px",
    display: "flex",
    alignItems: "center"
  },
  "& .keyFeature-white-container-gridView": {
    borderRadius: 8,
    background: "#F5F5F4",
    minHeight:"auto",
    padding: "32px 16px",
  },
  "& .keyFeature-main-text": {
    fontFamily: "Inter",
    marginBottom: 24,
    fontWeight: 700,
    fontSize: 24,
  },
  "& .keyFeature-main-text-gridView": {
    fontWeight: 700,
    fontFamily: "Inter",
    marginTop: 24,
    fontSize: 24,
  },
  "& .box-span": {
    color: "#DF4833"
  },
  "& .keyFeature-img-main": {
    padding: "24px",
    flexWrap: "wrap",
    gap: 24,
    height: 172,
    display: "flex",
    overflowY: "scroll",
    background: "#F0F0F0",
  },
  "& .keyFeature-img-main-gridView": {
    flexWrap: "wrap",
    gap: 24,
    display: "flex",
    height: 172,
    overflowY: "scroll",
    padding: "0 10px",
  },
  "& .img-loop": {
    height: 172,
    objectFit: "contain",
    width: 166,
  },
  "& .img-class": {
    width: 166,
    height: 172,
  },
  "& .keyFeature-Id-btn": {
    borderRadius: 8,
    color: "#DF4833",
    fontWeight: 700,
    fontFamily: "Inter",
    fontSize: 24,
    height: 58,
    width: "fit-content",
    textTransform: "none",
    background: "#FCEDEB",
    alignItems: "center",
    gap: 8,
    padding: "0px 24px",
    display: "flex",
    marginTop: 24,
    border: 'none',
  },
  "& .keyFeature-Id-btn-gridView": {
    display: "flex",
    fontFamily: "Inter",
    fontWeight: 700,
    width: "fit-content",
    borderRadius: 8,
    color: "#DF4833",
    margin: "24px 0",
    textTransform: "none",
    fontSize: 24,
    background: "#FCEDEB",
    alignItems: "center",
    gap: 8,
    border: 'none',
    padding: "0px 24px",
    height: 58,
  },
  "& .keyFeature-p-text": {
    marginTop: 10,
    color: "#4F5356",
    fontFamily: "Inter",
    fontSize: 16,
    marginBottom: 24,
    fontWeight: 400,
  },
  "& .keyFeature-box-search-loop": {
    background: "#F1F5F9",
    cursor: "pointer",
    height: 46,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
    width: "fit-content",
    "@media(max-width:550px)": {
      width: "100%",
    },
  },
  "& .keyFeature-gridView": {
    marginTop: 30
  },
  "& .keyFeature-box-search-loop-select": {
    width: "fit-content",
    display: 'flex',
    cursor: "pointer",
    borderRadius: 4,
    alignItems: 'center',
    height: 46,
    background: "#FCEDEB",
    padding: '0 16px',
    "@media(max-width:550px)": {
      width: "100%",
    },
  },
  "& .keyFeature-search-box-looking": {
    gap: 10,
    display: "flex",
    "@media(max-width:550px)": {
      flexWrap: "wrap",
    },
  },
  "& .keyFeature-search-grid": {
    justifyContent: 'space-between',
    display: "flex",
  },
  "& .keyFeature-box-name": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: 14,
    color: "#64748B",
  },
  "& .top-ten-result": {
    fontWeight: 400,
    fontFamily: "Inter",
    marginBottom: 5,
    fontSize: 16,
    color: "#DF4833",
  },
  "& .caseId-main": {
    fontWeight: 700,
    marginBottom: 30,
    fontFamily: "Inter",
    color: "#4F5356",
    fontSize: 24,
  },
  "& .keyfeature-box": {
    display: 'flex',
    marginBottom: 30,
    "@media(max-width:830px)": {
      flexWrap: "wrap",
    },
  },
  "& .download-Report-main": {
    gap: 24,
    display: 'flex',
    alignItems: 'center',
    "@media(max-width:830px)": {
      marginTop: 30
    },
  },
  "& .keyFeature-img-box": {
    borderRadius: 8,
    background: "#F1F5F9",
    padding: 10,
  },
  "& .listView-style": {
    cursor: "pointer"
  },
  "& .keyFeature-chart-box": {
    gap: 32,
    display: 'flex',
    "@media(max-width:900px)": {
      flexDirection: "column",
    },
  },
  "& .keyFeature-chart-box-gridView": {
    gap: 10,
    display: 'flex',
  },
  "& .keyFeature-chart-main-box": {
    padding: "24px",
    height: "274px",
    background: "#fff",
    width: "35%",
    borderRadius: 8,
    "@media(max-width:900px)": {
      width: "94%"
    },
    "@media(max-width:700px)": {
      width: "92%"
    },
    "@media(max-width:500px)": {
      width: "89%"
    },
    "@media(max-width:400px)": {
      width: "85%"
    },
  },
  "& .keyFeature-chart-main-box-gridView": {
    padding: "10px",
    borderRadius: 8,
    width: "50%",
    background: "#fff",
    height: "274px",
  },
  "& .keyFeature-Overlapping-main-box": {
    padding: "24px",
    borderRadius: 8,
    height: "274px",
    width: "35%",
    background: "#fff",
    "@media(max-width:900px)": {
      width: "94%"
    },
    "@media(max-width:700px)": {
      width: "92%"
    },
    "@media(max-width:500px)": {
      width: "89%"
    },
    "@media(max-width:400px)": {
      width: "85%"
    },
  },
  "& .keyFeature-Overlapping-main-box-gridView": {
    borderRadius: 8,
    height: "274px",
    background: "#fff",
    width: "50%",
    padding: "10px",
  },
  "& .KeyFeature-progressStyle": {
    borderRadius: "10px",
    width: '70%',
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#DF4833"
    }
  },
  "& .overlapping-Label": {
    fontFamily: "Inter",
    marginBottom: "5px",
    color: "#0F172A",
    fontWeight: 400,
    fontSize: 14,
  },
  "& .overlapping-percentage": {
    color: "#0F172A",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "12px",
  },
  "& .overlapping-head-Box": {
    justifyContent: "space-between",
    display: 'flex',
  },
  "& .overlapping-border": {
    background: "#E2E8F0",
    height: "1px",
    width: "100%",
    margin: "20px 0",
  },
  "& .overlapping-text": {
    fontFamily: "Inter",
    color: "#0F172A",
    fontSize: "12px",
    fontWeight: 400,
  },
  "& .percent-text": {
    color: "#64748B",
    fontSize: "12px",
    fontFamily: "Inter",
    fontWeight: 400,
  },
  "& .overlapping-box-container": {
    marginBottom: 25
  },
  "& .overlapping-Label-percentage": {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  "& .buttons-main-box": {
    display: "flex",
    flexWrap: "wrap",
    gap: 20,
    marginTop: 24,
  },
  "& .buttons-box": {
    gap: "10px",
    cursor: "pointer",
    background: "#F5F5F4",
    borderRadius: "8px",
    border: "1px solid #AEB0B1",
    padding: "10px 16px",
    alignItems: "center",
    display: "flex",
  },
  "& .link-text": {
    textDecoration: "none"
  },
  "& .buttons-box-red": {
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    borderRadius: "8px",
    gap: "10px",
    padding: "10px 16px",
    background: "#DF4833",
  },
  "& .button-text": {
    fontSize: "16px",
    fontWeight: 400,
    color: "#4F5356",
    fontFamily: "Inter",
  },
  "& .button-text-white": {
    fontWeight: 400,
    fontSize: "16px",
    color: "#FFFFFF",
    fontFamily: "Inter",
  },
  "& .report-buttons-box": {
    padding: "10px 0px 10px 16px",
    cursor: "pointer",
    background: "#EFF6FF",
    gap: "10px",
    width: "200px",
    alignItems: "center",
    borderRadius: "8px",
    display: "flex",
  },
  "& .report-button-text": {
    color: "#3B82F6",
    fontFamily: "Inter",
    fontWeight: 700,
    fontSize: "16px",
  }
});
// Customizable Area End
